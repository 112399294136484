import React, { useState, useEffect } from "react";
import '../App.css';
import "../css/style.css";
import "../css/bootstrap.min.css"
import "../css/default.css"
import "../css/slick.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faGooglePlus, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Image from "../img/current_memenber/Mr.Thirupathi - Mentor.png";
import Image2 from "../img/current_memenber/Muthu Kannan - President.jpeg";
import Image3 from "../img/current_memenber/Uma Meiyappan - Secretary.jpeg";
import Image4 from "../img/current_memenber/Lakshmanan VR - Treasurer.jpg";
import Image5 from "../img/current_memenber/N.Subramanian  - Ec Member.jpg";
import Image6 from "../img/current_memenber/VT.Chandhrasekharan - EC Member.jpg";
import Image7 from "../img/current_memenber/C.Sabari Shankar - EC Member.jpg";
import Header from "../../src/header/index";
import Footer from "../../src/footer/index";
import { useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import noImage from "../img/noimage.jpg";

function Member() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchDataforgetbyType = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/client/getbytypepost",
        {
          membertype: "Founding",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const clientIds = response.data.map((item) => item.clientid);
      localStorage.setItem('clientIdsofAssociateMember', JSON.stringify(clientIds));

      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCompanyCategories = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/companycategory/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setCompanyCategories(response.data);
    } catch (error) {
      console.error("Error fetching company categories:", error);
    }
  };
  useEffect(() => {
    fetchDataforgetbyType();
    fetchCompanyCategories();
    return () => {
      localStorage.removeItem("selectedCategory");
    };
  }, []);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'https://api.nagaratharcoc.com/api/v1/login/getclientdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        if (response.ok) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
  useEffect(() => {
    const filteredResults = data.filter((item) =>
      (selectedCategory ? item.companycategory === selectedCategory : true) &&
      (
        item.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.companyname.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.companycategory.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.address1.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredData(filteredResults);
  }, [searchQuery, data, selectedCategory]);

  const handleRowClick = async (item) => {
    try {
      // Assuming item.id is the memberid you want to pass
      const clientid = item.clientid;
      localStorage.setItem('clickedClientId', clientid);
      // Navigate to the UserProfile page or handle the response accordingly
      navigate(`/UserProfile`);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };
  useEffect(() => {
    const uniqueClientIds = new Set(); // Use a set for faster lookup of unique client IDs
    const filteredResults = [];

    // Iterate over the data
    data.forEach((item) => {
      // Check if the item's clientid is not in the uniqueClientIds set
      if (!uniqueClientIds.has(item.clientid)) {
        // If the clientid is not in the set, add it and return true
        uniqueClientIds.add(item.clientid);
        // Add the item to the filtered results
        filteredResults.push(item);
      }
    });

    // Define the order of member types
    const memberTypesOrder = ["Founding - President", "Founding - Secretary", "Founding - Treasurer", "Founding EC Member"];

    // Iterate over the member types order
    memberTypesOrder.forEach((memberType) => {
      // Iterate over the filtered results
      filteredResults.forEach((item) => {
        // Check if the current item's membertype matches the current member type in the order
        if (item.assignedmembertype.membertype === memberType) {
          // Add the item to the filtered results
          filteredResults.push(item);
        }
      });
    });

    setFilteredData(filteredResults);
  }, [data]);



  return (
    <div>
      <Header />


      <div class="our-team content-area">
        <div class="container">

          <div class="main-title">
            <h1>Founding Members</h1>
            <p>We established the chamber on 15th August 2016 with a vision of expanding our views to a large group of individuals.</p>
            <p>The former president, Mr.EMC Palaniappan planted the seeds for the growth. The growth is evolving with various presidents at present.


            </p>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <div class="row  d-flex justify-content-center">
                {filteredData
                  .filter((item) =>
                    item.assignedmembertype.some(
                      (type) => type.membertype === 'Founding - Mentor'
                    )
                  )
                  .map((filteredItem, index) => (
                    <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="team-1">
                        <div class="team-photo">
                          <a href="#">
                            <img src={filteredItem.profilepic || noImage} alt="agent" className="member-img" />
                          </a>
                        </div>
                        <div class="team-details text-center">
                          <h5><a href="#">{filteredItem.firstname}.{filteredItem.lastname} </a></h5>
                          <h6>{filteredItem.city}</h6>
                          <p>{filteredItem.companyname}<br />
                            {filteredItem.companycategory}<br />
                            {filteredItem.assignedmembertype && filteredItem.assignedmembertype.map((membership, index) => (membership.membertype.includes("Founding ") && (
                              <p key={index}>{membership.membertype}</p>
                            )
                            ))}</p>
                        </div>
                      </div>
                    </div>

                  ))}
              </div>
              <div class="row  d-flex justify-content-center">
                {filteredData
                  .filter((item) =>
                    item.assignedmembertype.some(
                      (type) => type.membertype === 'Founding - President'
                    )
                  )
                  .map((filteredItem, index) => (
                    <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="team-1">
                        <div class="team-photo">
                          <a href="#">
                            <img src={filteredItem.profilepic || noImage} alt="agent" className="member-img"/>
                          </a>
                        </div>
                        <div class="team-details text-center">
                          <h5><a href="#">{filteredItem.firstname}.{filteredItem.lastname} </a></h5>
                          <h6>{filteredItem.city}</h6>
                          <p>{filteredItem.companyname}<br />
                            {filteredItem.companycategory}<br />
                            {filteredItem.assignedmembertype && filteredItem.assignedmembertype.map((membership, index) => (membership.membertype.includes("Founding ") && (
                              <p key={index}>{membership.membertype}</p>
                            )
                            ))}</p>
                        </div>
                      </div>
                    </div>

                  ))}
              </div>


              <div className="row d-flex justify-content-center">
                {filteredData
                  .filter((item) =>
                    item.assignedmembertype.some(
                      (type) => type.membertype === 'Founding - Secretery' || type.membertype === 'Founding - Treasurer'
                    )
                  )
                  .map((filteredItem, index) => (
                    <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      {/* Render EC member details */}
                      <div className="team-1">
                        <div className="team-photo">
                          <a href="#">
                            <img src={filteredItem.profilepic || noImage} alt="agent" className="member-img"/>
                          </a>
                        </div>
                        <div class="team-details text-center">
                          <h5><a href="#">{filteredItem.firstname}.{filteredItem.lastname} </a></h5>
                          <h6>{filteredItem.city}</h6>
                          <p>{filteredItem.companyname}<br />
                            {filteredItem.companycategory}<br />
                            {filteredItem.assignedmembertype && filteredItem.assignedmembertype.map((membership, index) => (membership.membertype.includes("Founding ") && (
                              <p key={index}>{membership.membertype}</p>
                            )
                            ))}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="row d-flex justify-content-center">
                {filteredData
                  .filter((item) =>
                    item.assignedmembertype.some(
                      (type) => type.membertype === 'Founding - EC Member'
                    )
                  )
                  .map((filteredItem, index) => (
                    <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      {/* Render secretary or treasurer details */}
                      <div className="team-1">
                        <div className="team-photo">
                          <a href="#">
                            <img src={filteredItem.profilepic || noImage} alt="agent" className="member-img"/>
                          </a>
                        </div>
                        <div class="team-details text-center">
                          <h5><a href="#">{filteredItem.firstname}.{filteredItem.lastname} </a></h5>
                          <h6>{filteredItem.city}</h6>
                          <p>{filteredItem.companyname}<br />
                            {filteredItem.companycategory}<br />
                            {filteredItem.assignedmembertype && filteredItem.assignedmembertype.map((membership, index) => (membership.membertype.includes("Founding ") && (
                              <p key={index}>{membership.membertype}</p>
                            )
                            ))}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Member;    